import React from "react";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonButton,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonModal ,
} from "@ionic/react";
import { Route, Redirect } from "react-router-dom";
import { gameControllerOutline, syncOutline, logInOutline, logOutOutline } from "ionicons/icons";
import { useAuth } from "react-oidc-context";

import SelectHostname from "./pages/SelectHostname";
import Merge from "./pages/Merge";
import { Authenticator, useAuthenticator  } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useState, useEffect  } from 'react';
import { useHistory } from "react-router-dom";
import { useDisplayMode } from "./hooks/useDevice";

const HostnameTabs: React.FC = () => {
  const auth = useAuth();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [showSignIn, setShowSignIn] = useState(false);
  const history = useHistory();
  const { isIOS, isStandalone, isBrowserOnMobile,  isChromeAndroid,isChromeiOS } = useDisplayMode();
  
  let paddingBottomValue = 'env(safe-area-inset-bottom)'; // Default fallback

  if (isBrowserOnMobile && !isChromeiOS && !isChromeAndroid) {
    paddingBottomValue = 'calc(85px + env(safe-area-inset-bottom))'; // Case 1: Mobile browser
  } else if (isIOS && isStandalone) {
    paddingBottomValue = 'calc(50px + env(safe-area-inset-bottom))'; // Case 2: iOS PWA No cambiar en 50 esta bien
  }
  else if (!isIOS && isStandalone) {
    paddingBottomValue = 'calc(env(safe-area-inset-bottom))'; // Case 2: iOS PWA No cambiar en 50 esta bien
  }
  else if (isChromeiOS) {
    paddingBottomValue = 'calc(110px + env(safe-area-inset-bottom))'; // Case 2: iOS PWA No cambiar en 50 esta bien
  }  
  else if (isChromeAndroid) {
    paddingBottomValue = 'calc(50px + env(safe-area-inset-bottom))'; // Case 2: iOS PWA No cambiar en 50 esta bien
  }  



  useEffect(() => {
    if (user) {
      history.push("/hostname/select-hostname");
      setShowSignIn(false); // Close modal
    }
  }, [user, history]);


    // ✅ Check if `auth` is undefined (prevents crashes)
    if (!auth) {
      return <div>Loading authentication...</div>;
    }


  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonLabel>
            {user ? `Welcome, ${user.signInDetails?.loginId}` : "Welcome"}
          </IonLabel>
          <IonButtons slot="end">
            {!user ? (
              <IonButton fill="clear" onClick={() => setShowSignIn(true)}>
                <IonIcon icon={logInOutline} />
                Sign In
              </IonButton>
            ) : (
              <IonButton fill="clear" onClick={() => signOut()}>
                <IonIcon icon={logOutOutline} />
                Sign Out
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonModal isOpen={showSignIn} onDidDismiss={() => setShowSignIn(false)}>
        <Authenticator />
      </IonModal>

      {/* Rest of IonTabs Navigation */}
      <IonTabs>
          <IonRouterOutlet>
            <Route path="/hostname/select-hostname" component={SelectHostname} exact/>
            <Route path="/hostname/merge" component={Merge} exact/>
            <Route exact path="/hostname">
              <Redirect to="/hostname/select-hostname" />
            </Route>
          </IonRouterOutlet>

          <IonTabBar slot="bottom" style={{paddingBottom: paddingBottomValue,}}>
            <IonTabButton tab="select-hostname" href="/hostname/select-hostname" >
              <IonIcon icon={gameControllerOutline} />
              <IonLabel>Ori-Control</IonLabel>
            </IonTabButton>
            <IonTabButton tab="merge" href="/hostname/merge" >
              <IonIcon icon={syncOutline} />
              <IonLabel>Merge Files</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
    </>
  );
};



export default HostnameTabs;
