import React, { useContext, useEffect, useState  } from "react";
import { ProvideStore } from "./hooks/useStore";
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel } from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import Config from "./pages/Config";
import Control from "./pages/Control";
import Jobs from "./pages/Jobs";
import { SelectedOriContext } from "./context/SelectedOriContext"; // ✅ Import context
import { listOutline, listSharp, cogOutline, cogSharp, playCircleOutline, playCircleSharp } from 'ionicons/icons';
import { ToastContainer } from 'react-toastify';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { GlobalUserProvider } from './GlobalStoreContext';
import { useDisplayMode } from "./hooks/useDevice";


const AppTabs: React.FC = () => {
  const { selectedOri } = useContext(SelectedOriContext);
  const [userId, setUserId] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { isIOS, isStandalone, isBrowserOnMobile,  isChromeAndroid,isChromeiOS } = useDisplayMode();
  
  let paddingBottomValue = 'env(safe-area-inset-bottom)'; // Default fallback

  if (isBrowserOnMobile && !isChromeiOS && !isChromeAndroid) {
    paddingBottomValue = 'calc(85px + env(safe-area-inset-bottom))'; // Case 1: Mobile browser
  } else if (isIOS && isStandalone) {
    paddingBottomValue = 'calc(50px + env(safe-area-inset-bottom))'; // Case 2: iOS PWA No cambiar en 50 esta bien
  }
  else if (!isIOS && isStandalone) {
    paddingBottomValue = 'calc(env(safe-area-inset-bottom))'; // Case 2: iOS PWA No cambiar en 50 esta bien
  }
  else if (isChromeiOS) {
    paddingBottomValue = 'calc(110px + env(safe-area-inset-bottom))'; // Case 2: iOS PWA No cambiar en 50 esta bien
  }  
  else if (isChromeAndroid) {
    paddingBottomValue = 'calc(50px + env(safe-area-inset-bottom))'; // Case 2: iOS PWA No cambiar en 50 esta bien
  } 

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const session = await fetchAuthSession();
        const user = await getCurrentUser();

        if (session.tokens) {
          setAccessToken(session.tokens.accessToken.toString());
          setUserId(user.userId);
        }
      } catch (err) {
        console.error("Error fetching user/session info:", err);
      }
    };

    fetchUserInfo();
  }, []);

  if (!selectedOri) {
    return <Redirect to="/hostname/select-hostname" />;
  }

  // Only render if userId and accessToken are available
  if (!userId || !accessToken) {
    return <div>Loading...</div>;
  }



  return (
<GlobalUserProvider userId={userId} accessToken={accessToken} selectedOri={selectedOri}>
    <ProvideStore user_id={userId} accessToken={accessToken} selectedOri={selectedOri}>
    <ToastContainer />
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/config" render={() => <Config />} exact />
        <Route path="/control" render={() => <Control/>} exact />
        <Route path="/jobs" render={() => <Jobs />} exact />
        <Redirect exact path="/" to="/config" />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" style={{paddingBottom: paddingBottomValue,}}>
        <IonTabButton tab="control" href="/control">
          <IonIcon ios={playCircleOutline} md={playCircleSharp} />
          <IonLabel>Control</IonLabel>
        </IonTabButton>
        <IonTabButton tab="jobs" href="/jobs">
          <IonIcon ios={listOutline} md={listSharp} />
          <IonLabel>Jobs</IonLabel>
        </IonTabButton>
        <IonTabButton tab="config" href="/config">
          <IonIcon ios={cogOutline} md={cogSharp} />
          <IonLabel>Config</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
    </ProvideStore>
    </GlobalUserProvider>
  );
};

export default AppTabs;
