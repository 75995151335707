import React, { useState, useEffect, useContext  } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonList,
  IonText,
  IonIcon,
  IonFab, 
  IonFabButton,
  IonFabList,
  
} from "@ionic/react";
import { useAuth } from "react-oidc-context";
import { IoIosRemoveCircle, IoMdCheckmarkCircle, IoMdHelpCircle  } from "react-icons/io";
import { filterOutline,playOutline, checkmarkCircleOutline, alertCircleOutline , warningOutline, closeOutline, refreshOutline} from "ionicons/icons"; // Import refresh icon
import { useIonRouter } from "@ionic/react";
import { SelectedOriContext } from "../context/SelectedOriContext";
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useWebSocketContext } from "../WebSocketContext";
import {getUserOriCollectors, sendOriCollectorCommand} from "../data/api";
import { useWebSocketStore } from '../stores/zustang';
import { time } from "console";

interface OriCollectorStatus {
  status_name?: string;
  progress?: number;
  position?: number;
  total_positions?: number;
  farm?: string;
  error?: number;
  [key: string]: any;
}

const SelectHostname: React.FC = ({  }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showFabMenu, setShowFabMenu] = useState<boolean>(false);

  const router = useIonRouter();
  const { setSelectedOri } = useContext(SelectedOriContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const statuses = useWebSocketStore((state) => state.statuses);
  const updateStatus = useWebSocketStore((state) => state.updateStatus);
  const resetStore = useWebSocketStore((state) => state.resetStore);
  const heartbeat = useWebSocketStore((state) => state.heartbeat);
  const updateHeartbeat = useWebSocketStore((state) => state.updateHeartbeat);

  const { wsConnected, reconnectWebSocket } = useWebSocketContext();

  const [filteredOriCollectors, setFilteredOriCollectors] = useState<Record<string, OriCollectorStatus>>({});
  const handleUseOriCollector = (oriId: string) => {
    console.log("Navigating to:", `/constrol with ${oriId}`);
    setSelectedOri(oriId);
    router.push(`/control`, "forward");
  };
  

  useEffect(() => {
    setFilteredOriCollectors(statuses);
  }, [statuses, heartbeat]);

  useEffect(() => {
    const checkAndFetchOriCollectors = async () => {
      try {
        const session = await fetchAuthSession();
  
        if (session.tokens) {
          setIsAuthenticated(true);
  
          console.log("✅ User session valid. Fetching user and ori-collectors...");
  
          const user = await getCurrentUser();
  
          await fetchOriCollectors(session.tokens.accessToken.toString(), user.userId);
        } else {
          setIsAuthenticated(false);
          setFilteredOriCollectors({});
          resetStore();
          setLoading(false);
        }
      } catch (error) {
        console.error('❌ Error checking session:', error);
        setIsAuthenticated(false);
        setFilteredOriCollectors({});
        resetStore();
        setLoading(false);
      }
    };
  
    checkAndFetchOriCollectors();
  
    // 🔥 Hub listener to catch signIn/signOut events
    const unsubscribe = Hub.listen('auth', async (data) => {
      const { payload } = data;
      console.log("Auth Event:", payload);
  
      switch (payload.event) {
        case 'signedIn':
          console.log("✅ User signed in");
          setIsAuthenticated(true);
  
          try {
            // Fetch session and OriCollectors again after sign-in
            const session = await fetchAuthSession();
            if (session.tokens) {
              const user = await getCurrentUser();
              await fetchOriCollectors(session.tokens.accessToken.toString(), user.userId);
            }
          } catch (err) {
            console.error("Error re-fetching after sign-in:", err);
          }
          break;
  
        case 'signedOut':
          console.log("🚪 User signed out");
          setIsAuthenticated(false);
          resetStore();
          setFilteredOriCollectors({});
          setLoading(false);
          break;
  
        case 'tokenRefresh_failure':
          console.log("❌ Token refresh failed");
          setIsAuthenticated(false);
          resetStore();
          setFilteredOriCollectors({});
          setLoading(false);
          break;
      }
    });
  
    return () => unsubscribe();
  
  }, []);

  useEffect(() => {
    const checkHeartbeat = () => {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeoutSeconds = 360; // 6 minutes
      console.log("heartbeatRefresh ", heartbeat)
      Object.keys(heartbeat || {}).forEach(deviceId => {
        const lastHeartbeat = heartbeat[deviceId];
        const secondsSinceLast = currentTime - lastHeartbeat.timestamp;
  
        const currentStatus = statuses[deviceId]?.status_name;
        console.log("Current status: ", currentStatus, secondsSinceLast, lastHeartbeat)
        if (secondsSinceLast > timeoutSeconds && currentStatus !== "DISCONNECTED") {
          console.warn(`❌ ${deviceId} no heartbeat! Marking as DISCONNECTED`);
          updateStatus(deviceId, {
            ...statuses[deviceId],
            status_name: "DISCONNECTED",
            status:10,
            message: "Device disconnected (no heartbeat)",
          });
          updateHeartbeat(deviceId, {
            status: "DISCONNECTED",
            message: "Device disconnected (no heartbeat)",
          });
        }
  
        if (secondsSinceLast <= timeoutSeconds && currentStatus === "DISCONNECTED") {
          console.log(`✅ ${deviceId} heartbeat resumed! Marking as CONNECTED`);
   

          updateStatus(deviceId, {
            ...statuses[deviceId],
            status_name: statuses[deviceId].status_name, // or fetch from a stored last known status
            message: "Device reconnected (heartbeat received)",
          });
          updateHeartbeat(deviceId, {
            timestamp: heartbeat[deviceId].timestamp,
            status: heartbeat[deviceId].status,
            message: "Device reconnected (heartbeat received)",
          });
        }
      });
    };
    
    const interval = setInterval(checkHeartbeat, 30 * 1000);
    return () => clearInterval(interval);
  }, [heartbeat, statuses]);
  
  const fetchOriCollectors = async (accessToken: string, user_id:string) => {

    try {
      console.log("🔄 Fetching Ori-Collectors...");
      
      const data = await getUserOriCollectors(user_id, accessToken);
  
      console.log("✅ Fetched Ori-Collectors:", data);

          // ✅ Push to zustand statuses one by one
      Object.entries(data).forEach(([hostname, status]) => {
        if (status && typeof status === 'object') {
          updateStatus(hostname, {
            ...status,
            type: "status"
          });
        } else {
          console.warn(`⚠️ Invalid status for device ${hostname}:`);
        }
      });
      const store = useWebSocketStore.getState();
      console.log("✅ Zustand fresh statuses:", store.statuses);
      setFilteredOriCollectors(store.statuses);
    } catch (error) {
      console.error("❌ Error fetching Ori-Collectors:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterOriCollectors = (filterType: string) => {
    if (filterType === "ALL") {
      setFilteredOriCollectors(statuses);
      return;
    }

    let filtered = Object.entries(statuses)
      .filter(([_, status]) => {
        if (filterType === "START") return status.status!== 10;
        if (filterType === "FINISH") return status.status_name === "FINISH";
        if (filterType === "ABOUT_TO_FINISH") {
          return (
            status.position !== undefined &&
            status.total_positions !== undefined &&
            status.total_positions > 0 &&
            (status.position / status.total_positions) >= 0.6 //acuerdate de cambiaro a 80% o asi
          );
        }
        if (filterType === "ERRORS") return status.error && status.error > 0;
        return false;
      })
      .sort(([_, a], [__, b]) => (a.farm || "").localeCompare(b.farm || ""));

    setFilteredOriCollectors(Object.fromEntries(filtered));
  };
  
  // Circular Progress Bar
  const renderProgressBar = (hostStatus: OriCollectorStatus, index: number) => (
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
      <div style={{ position: 'relative', width: '100px', height: '100px' }}>
        <svg viewBox="0 0 36 36" className="circular-chart" style={{ width: '100%', height: '100%' }}>
          <path
            className="circle-bg"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke="#e6e6e6"
            strokeWidth="2"
          />
          <path
            className="circle"
            stroke={`url(#progressGradient-${index})`}
            strokeDasharray={`${Math.round((hostStatus.progress ?? 0) * 100)}, 100`}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            strokeWidth="2.5"
          />
          <text x="18" y="20.35" className="percentage" textAnchor="middle" fontSize="6px" fill="#333">
            {Math.round((hostStatus.progress ?? 0) * 100)}%
          </text>
          <defs>
            <linearGradient id={`progressGradient-${index}`} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#32CD32" />
              <stop offset="100%" stopColor="#006400" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );

  const renderDefaultLayout = () => (
    <div style={{ textAlign: 'center' }}>
      <IoMdHelpCircle size={80} color="gray" />
      <p>❌ Status Unknown</p>
    </div>
  );

  const renderCardLayout = (hostStatus: OriCollectorStatus, index: number, oriId:string) => {
    switch (hostStatus.status_name) {
      case 'START':
        return renderStartLayout(hostStatus, index, oriId);
      case 'STOP':
        return renderStopLayout(hostStatus, oriId);
      case 'PAUSE':
        return renderPauseLayout(hostStatus, index, oriId);
      case 'FINISH':
        return renderFinishLayout(hostStatus, oriId);
      default:
        return renderDefaultLayout();
    }
  };

   const renderStartLayout = (hostStatus: OriCollectorStatus, index: number, oriId:string) => (
      <>
        {renderProgressBar(hostStatus, index)}
        <p style={{ color: 'green', fontWeight: 'bold', fontSize: '18px', textAlign: 'center' }}>▶️ Running</p>
        {renderJobDetails(hostStatus, oriId)}
      </>
    );

    const renderButton = ( oriId:string) =>(  
    <IonButton expand="block" onClick={() => handleUseOriCollector(oriId)}>
      USE ORI-COLLECTOR
    </IonButton>
     );
  
    const renderStopLayout = (hostStatus: OriCollectorStatus, oriId:string) => (
      <div style={{ textAlign: 'center' }}>
        <IoIosRemoveCircle size={50} color="red" />
        <p style={{ color: 'red', fontWeight: 'bold', fontSize: '18px' }}>🛑 Process Stopped</p>
        {renderButton(oriId)}
      </div>
    );
  
    const renderPauseLayout = (hostStatus: OriCollectorStatus, index: number, oriId:string) => (
      <>
  
        {renderProgressBar(hostStatus, index)}
        <p style={{ color: 'orange', fontWeight: 'bold', fontSize: '18px', textAlign: 'center' }}>⏸ Process Paused</p>
        {renderJobDetails(hostStatus, oriId)}
      </>
    );
  
    const renderFinishLayout = (hostStatus: OriCollectorStatus, oriId:string) => (
      <div style={{ textAlign: 'center' }}>
        <IoMdCheckmarkCircle size={50} color="green" />
        <p style={{ color: 'green', fontWeight: 'bold', fontSize: '18px' }}>🎉 Process Finished</p>
        <p><strong>Farm:</strong> {hostStatus.farm || 'N/A'} </p>
        <p style={{ color:(hostStatus.error ?? 0) > 0 ? 'red' : 'inherit' }}>
          <strong>Error:</strong> {(hostStatus.error ?? 0) > 0 ? `⚠️` : `✅`}
        </p>
        {renderButton(oriId)}
      </div>
    );

      const renderJobDetails = (hostStatus: OriCollectorStatus, oriId:string) => (
        <div style={{ textAlign: 'center', marginTop: 'auto' }}>
          <p><strong>Farm:</strong> {hostStatus.farm || 'N/A'} </p>
          {/*<p><strong>Job ID:</strong> {hostStatus.id_job || 'N/A'}</p>*/}
          <p><strong>Position:</strong> {hostStatus.position}/{hostStatus.total_positions}</p>
          <p style={{ color: (hostStatus.error ?? 0) > 0 ? 'red' : 'inherit' }}>
            <strong>Error:</strong> {(hostStatus.error ?? 0) > 0 ? `⚠️` : `✅`}
          </p>
          {renderButton(oriId)}
        </div>
      );


  return (
  <IonPage>
    <IonHeader collapse="fade">
      <IonToolbar>
        <IonTitle className="centered-title">Ori-Collector Control Panel</IonTitle>
      </IonToolbar>
    </IonHeader>
    
      <IonContent >
        {!isAuthenticated ? (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh', // full screen height
        }}>
          <IonText color="primary" style={{ fontSize: "18px", fontWeight: "bold" }}>
            Please sign in to access Ori-Collectors.
          </IonText>
        </div>
        ) : loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // full screen height
          }}
        >
          <div
            style={{
              width: '76px',
              height: '76px',
              border: '6px solid #ccc',
              borderTop: '6px solid #007bff',
              borderRadius: '50%',
              animation: 'spin 0.8s linear infinite',
              marginBottom: '20px',
            }}
          ></div>

          <IonText color="primary" style={{ fontSize: "18px", fontWeight: "bold" }}>
            Connecting to Ori-Collectors...
          </IonText>
        </div>
        ) :
        Object.keys(statuses).length > 0 ? (
   
            <IonList>
              {Object.entries(filteredOriCollectors).map(([oriId, status], index) => (
                <IonCard key={index} style={{ padding: "20px", textAlign: "center" }}>
                  <IonCardHeader>
                    <IonCardTitle>{oriId}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    {renderCardLayout(status, index, oriId)}
                  </IonCardContent>
                </IonCard>
              ))}
            </IonList>

        ) : (
          <IonText color="medium">No Ori-Collectors found.</IonText>
        )}
      </IonContent>

    <IonFab vertical="bottom" horizontal="end" slot="fixed">
    {!wsConnected  ? (
      <IonFabButton color="danger" onClick={reconnectWebSocket}>
        <IonIcon icon={refreshOutline} />
      </IonFabButton>
    ): (
      <IonFabButton color="primary" onClick={() => window.location.reload()}>
        <IonIcon icon={refreshOutline} />
      </IonFabButton>
    )}
    </IonFab>
    <IonFab slot="fixed" vertical="bottom" horizontal="start">
        <IonFabButton onClick={() => setShowFabMenu(!showFabMenu)}>
          <IonIcon icon={filterOutline} />
        </IonFabButton>

        <IonFabList side="top" activated={showFabMenu}>
          <IonFabButton onClick={() => filterOriCollectors("START")}>
            <IonIcon icon={playOutline} />
          </IonFabButton>
          <IonFabButton onClick={() => filterOriCollectors("FINISH")}>
            <IonIcon icon={checkmarkCircleOutline} />
          </IonFabButton>
          <IonFabButton onClick={() => filterOriCollectors("ABOUT_TO_FINISH")}>
            <IonIcon icon={alertCircleOutline} />
          </IonFabButton>
          <IonFabButton onClick={() => filterOriCollectors("ERRORS")}>
            <IonIcon icon={warningOutline} />
          </IonFabButton>
          <IonFabButton onClick={() => filterOriCollectors("ALL")}>
            <IonIcon icon={closeOutline} />
          </IonFabButton>
        </IonFabList>
      </IonFab>
    </IonPage>
  );
};

export default SelectHostname;
