export function useDisplayMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /android/i.test(navigator.userAgent);
    const isMobile = isIOS || isAndroid;
    const isBrowserOnMobile = !isStandalone && isMobile;
    const isChromeAndroid = /Chrome/.test(navigator.userAgent) && /Android/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && !/OPR/.test(navigator.userAgent);
    // Chrome on iOS uses 'CriOS'
    const isChromeiOS = /CriOS/.test(navigator.userAgent);
    
  
    return { isIOS, isStandalone, isMobile, isBrowserOnMobile, isChromeAndroid,isChromeiOS};
  }
  